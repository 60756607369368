/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import { Helmet } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'

import { GlobalStyle } from 'styles/global-styles'

import { I18nextProvider } from 'react-i18next'
import i18n from 'locales/i18n'
import { ConfigProvider, theme } from 'antd'
import { Pages } from './pages'

declare global {
  interface Window {
    nostr: {
      enabled: boolean
      getPublicKey: () => string
      signEvent: (event: Event) => Promise<Event>
    }
  }
}

export const App: () => JSX.Element = () => {
  return (
    <ConfigProvider
      theme={{
        algorithm: theme.darkAlgorithm,
        token: {
          colorPrimary: '#000',
          colorText: '#000',
          colorBgBase: '#fff',
          fontFamily: 'Montserrat',
          colorSplit: '#000',
          fontSize: 18,
        },
        components: {
          Timeline: {
            tailColor: '#000'
          },
        },
      }}
    >
      <BrowserRouter>
        <Helmet
          titleTemplate='%s - D & C'
          defaultTitle='D & C'
          htmlAttributes={{ lang: i18n.language }}
        >
          <meta name='description' content='D & C' />
        </Helmet>
        <I18nextProvider i18n={i18n}>
          <Pages />
        </I18nextProvider>
        <GlobalStyle />
      </BrowserRouter>
    </ConfigProvider>
  )
}
