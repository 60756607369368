/**
 * Asynchronously loads the component for TimelinePage
 */

import { lazyLoad } from 'utils/lazyLoad'

export const LocationPage = lazyLoad(
  async () => await import('./index'),
  (module) => module.LocationPage,
)
