/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import { Route, Routes, useNavigate } from 'react-router-dom'

import { HomePage } from '../pages/HomePage/Loadable'
import { TimelinePage } from './TimelinePage/Loadable'
import { useTranslation } from 'react-i18next'
import { Button, Col, Drawer, Radio, RadioChangeEvent, Row, Typography } from 'antd'
import { useState } from 'react'
import { MenuOutlined } from '@ant-design/icons'
import { NotFoundPage } from 'app/components/NotFoundPage/Loadable'
import { GiftsPage } from './GiftsPage/Loadable'
import { changeLanguage } from 'i18next'
import { LocationPage } from './LocationPage/Loadable'
import { CityPage } from './CityPage'
import { FormPage } from './FormPage/Loadable'
import { FaqPage } from './FaqPage/Loadable'

export const Pages: () => JSX.Element = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  const [open, setOpen] = useState(false)

  const showDrawer = (): void => {
    setOpen(true)
  }

  const onClose = (): void => {
    setOpen(false)
  }

  const goTo = (path: string): void => {
    navigate(path)
    setOpen(false)
  }

  const onChangeLanguage = async (e: RadioChangeEvent): Promise<void> => {
    await changeLanguage(e.target.value)
  }

  const title = (
    <Row justify='start' gutter={[16, 0]}>
      <Col span='24'>
        <Row justify='start'>
          <Typography.Title level={2} style={{ fontFamily: 'PlayfairDisplay' }}>D & C</Typography.Title>
        </Row>
      </Col>
      <Col span='24'>
        <Row justify='start'>
          <Typography.Text>{t('Saturday, June 7, 2025')}</Typography.Text>
        </Row>
      </Col>
    </Row>
  )

  return (
    <Row justify='space-around' style={{ padding: '16px 0' }}>
      <Col xs={{ span: 22 }} lg={{ span: 14 }}>
        <Row justify='space-between'>
          <Col span='10'>
            <Button onClick={() => showDrawer()}>
              <MenuOutlined style={{ top: -3, position: 'inherit' }} />
              {t('Menu')}
            </Button>
          </Col>
          <Col span='4'>
            <Row
              justify='center'
              onClick={() => navigate('/')}
              style={{ fontFamily: 'PlayfairDisplay' }}
            >
              D & C
            </Row>
          </Col>
          <Col span='10'>
            <Row justify='end'>
              <Radio.Group onChange={onChangeLanguage} defaultValue={i18n.language}>
                <Radio.Button value='es'>
                  <span className='fi fi-es'></span>
                </Radio.Button>
                <Radio.Button value='gb'>
                  <span className='fi fi-gb'></span>
                </Radio.Button>
              </Radio.Group>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col xs={{ span: 22 }} lg={{ span: 14 }} style={{ padding: '20px 0' }}>
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/timeline' element={<TimelinePage />} />
          <Route path='/location' element={<LocationPage />} />
          <Route path='/gifts' element={<GiftsPage />} />
          <Route path='/city' element={<CityPage />} />
          <Route path='/rsvp' element={<FormPage />} />
          <Route path='/faq' element={<FaqPage />} />
          <Route path='*' element={<NotFoundPage />} />
        </Routes>
        <Drawer title={title} onClose={onClose} open={open} placement='left'>
          <Row gutter={[0, 12]}>
            <Button type='text' onClick={() => goTo('/')} block size='large'>
              {t('Home')}
            </Button>
            <Button type='text' onClick={() => goTo('/rsvp')} block size='large'>
              {t('RSVP')}
            </Button>
            <Button type='text' onClick={() => goTo('/timeline')} block size='large'>
              {t('Timeline')}
            </Button>
            <Button type='text' onClick={() => goTo('/location')} block size='large'>
              {t('Location')}
            </Button>
            <Button type='text' onClick={() => goTo('/city')} block size='large'>
              {t('City')}
            </Button>
            <Button type='text' onClick={() => goTo('/gifts')} block size='large'>
              {t('Gifts')}
            </Button>
            <Button type='text' onClick={() => goTo('/faq')} block size='large'>
              {t('FAQ')}
            </Button>
          </Row>
        </Drawer>
      </Col>
      <Col span='24'>
        <Row>
          <Col span={8} offset={8}>
            <Row justify='center'>
              <Typography.Text style={{ color: '#000' }}>{'Made with ❤️'}</Typography.Text>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
