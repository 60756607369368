/**
 * Asynchronously loads the component for FormPage
 */

import { lazyLoad } from 'utils/lazyLoad'

export const FaqPage = lazyLoad(
  async () => await import('./index'),
  (module) => module.FaqPage,
)
