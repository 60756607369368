/**
 * Asynchronously loads the component for TimelinePage
 */

import { lazyLoad } from 'utils/lazyLoad'

export const TimelinePage = lazyLoad(
  async () => await import('./index'),
  (module) => module.TimelinePage,
)
