import { PushpinOutlined } from '@ant-design/icons'
import { Col, Row, Tabs, TabsProps, Typography, Image, List, Tag, Divider, Alert } from 'antd'
import { Content } from 'antd/es/layout/layout'
import { useTranslation } from 'react-i18next'

export const CityPage: () => JSX.Element = () => {
  const { t } = useTranslation()

  const bars = [
    {
      title: 'Real Alcázar de Sevilla',
      link: ''
    },
  ]

  const movies = [
    {
      title: 'Real Alcázar de Sevilla',
      link: 'https://g.page/realalcazarsevilla?share',
      visit: 'https://www.alcazarsevilla.info/rodaje-juego-tronos.html',
      movie: 'Game of Thrones',
    },
    {
      title: 'Reales Atarazanas',
      link: 'https://www.google.es/maps/@37.3851735,-5.9953442,3a,75y,138.31h,85.88t/data=!3m6!1e1!3m4!1sIxq8qrEXoU3Ug_awnft2UQ!2e0!7i13312!8i6656?hl=es',
      visit: 'https://academiaparaninfo.wordpress.com/2018/03/28/las-atarazanas-de-sevilla-escenario-de-la-serie-juego-de-tronos/',
      movie: 'Game of Thrones',
    },
    {
      title: 'Itálica',
      link: 'https://goo.gl/maps/f5ZWGaSCvGTVYxSG7',
      visit: 'https://www.italicasevilla.org/rodaje-juego-tronos.html',
      movie: 'Game of Thrones',
    },
    {
      title: 'Plaza de España',
      link: 'https://goo.gl/maps/mShWYvYg637jE8Fz7',
      visit: 'https://sevilla.abc.es/sevilla/sevi-estrecha-relacion-entre-star-wars-y-sevilla-201512180716_noticia.html',
      movie: 'Star Wars',
    },
  ]

  const hidden = [
    {
      title: 'Baños de Doña María de Padilla',
      link: 'https://goo.gl/maps/uYh1BrSBGSR8w4b29',
      visit: '',
    },
    {
      title: 'Reales Atarazanas',
      link: 'https://www.google.es/maps/@37.3851735,-5.9953442,3a,75y,138.31h,85.88t/data=!3m6!1e1!3m4!1sIxq8qrEXoU3Ug_awnft2UQ!2e0!7i13312!8i6656?hl=es',
      visit: '',
    },
    {
      title: 'Glorieta de Bécquer',
      link: 'https://goo.gl/maps/SRtGTuqf2GYkqDio6',
      visit: '',
    },
    {
      title: 'Fuente de la Calle Judería',
      link: 'https://goo.gl/maps/nDLKYzSh61hj2Kq47',
      visit: '',
    },
    {
      title: 'Calle Verde',
      link: 'https://goo.gl/maps/4XoDAV915QqeLj886',
      visit: '',
    },
    {
      title: 'Columnas Romanas',
      link: 'https://goo.gl/maps/EvACnBbR2xytKzmT8',
      visit: '',
    },
  ]

  const museums = [
    {
      title: 'Museo de Bellas Artes',
      link: 'https://goo.gl/maps/tVuatye6k6jomvxf7',
      visit: '',
    },
    {
      title: 'Museo Catedralicio',
      link: 'https://goo.gl/maps/Tg4vsBRNAZiMyAcm7',
      visit: '',
    },
    {
      title: 'Museo del Baile Flamenco',
      link: 'https://goo.gl/maps/zvPkSAjquS1L8wnm6',
      visit: '',
    },
    {
      title: 'Museo de Artes y Costumbres Populares',
      link: 'https://goo.gl/maps/sVh7YPB2emQEVwh76',
      visit: '',
    },
    {
      title: 'Centro Cerámica Triana',
      link: 'https://goo.gl/maps/41Zuy2Fmi1C5qEtKA',
      visit: '',
    },
    {
      title: 'Centro Andaluz de Arte Contemporáneo',
      link: 'https://goo.gl/maps/joSkKyTHwP6c2X6d8',
      visit: '',
    },
    {
      title: 'Museo Arqueológico',
      link: 'https://goo.gl/maps/SExVzXQq5T1GyKhA6',
      visit: '',
    },
    {
      title: 'Museo Naval',
      link: 'https://goo.gl/maps/KkgymM4XFvk9VH1c7',
      visit: '',
    },
  ]

  const popular = [
    {
      title: 'Parque de María Luisa',
      visit: 'Free Visit',
      link: 'https://goo.gl/maps/CBfHT3VBSxw9HCAs8',
    },
    {
      title: 'Plaza de España',
      visit: 'Free Visit',
      link: 'https://goo.gl/maps/mShWYvYg637jE8Fz7',
    },
    {
      title: 'Real Alcázar de Sevilla',
      visit: 'Tickets',
      link: 'https://g.page/realalcazarsevilla?share',
    },
    {
      title: 'Santa Cruz (Neighbourhood)',
      visit: 'Free Visit',
      link: 'https://goo.gl/maps/TpDL4NTdpsjMsvYDA',
    },
    {
      title: 'Torre del Oro',
      visit: 'Free Visit',
      link: 'https://goo.gl/maps/UxxJBX3gnzSKXx917',
    },
    {
      title: 'Plaza de toros de la Real Maestranza',
      visit: 'Tickets',
      link: 'https://goo.gl/maps/fGAMuZC83DR5XTyM6',
    },
    {
      title: 'Catedral de Sevilla',
      visit: 'Tickets',
      link: 'https://goo.gl/maps/i9vSevQLHrAG7PXj9',
    },
    {
      title: 'Giralda',
      visit: 'Tickets',
      link: 'https://goo.gl/maps/KKnVkrQDiKuk1JQW6',
    },
    {
      title: 'Plaza del Cabildo',
      visit: 'Free Visit',
      link: 'https://goo.gl/maps/fbJuJ1vvTCkjuwgk6',
    },
    {
      title: 'Casa de Pilatos',
      visit: 'Tickets',
      link: 'https://goo.gl/maps/tkrZgrVVWS8vTX6U6',
    },
    {
      title: 'Iglesia Colegial del Divino Salvador',
      visit: 'Tickets',
      link: 'https://goo.gl/maps/D8NJ7Uk9P3bHUXKGA',
    },
    {
      title: 'Iglesia San Luis de los Franceses',
      visit: 'Tickets',
      link: 'https://goo.gl/maps/QCUfo4TZwNeUNJ3S7',
    },
    {
      title: 'Setas de Sevilla',
      visit: 'Tickets',
      link: 'https://goo.gl/maps/ok5WeDYLGxP3YsJp6',
    },
    {
      title: 'Palacio de las Dueñas',
      visit: 'Tickets',
      link: 'https://g.page/palaciodelasduenas?share',
    },
    {
      title: 'Puente de Isabel II (Puente de Triana)',
      visit: 'Free Visit',
      link: 'https://goo.gl/maps/dBgFUxVhz4QgXGEE9',
    },
    {
      title: 'Triana (Neighbourhood)',
      visit: 'Free Visit',
      link: 'https://goo.gl/maps/ydKPaqas5Nf9jqvN7',
    },
    {
      title: 'Macarena (Neighbourhood)',
      visit: 'Free Visit',
      link: 'https://goo.gl/maps/eMpTHHKKQRg9zsAc7',
    },
  ]

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: t('Traveling'),
      children: (
        <Row justify='space-around'>
          <Col span='18'>
            <Row justify='space-around'>
              <Typography.Title level={3}>{t('Airport')}</Typography.Title>
            </Row>
            <Row justify='space-around'>
              <Typography.Text>
                {t(
                  'The SVQ airport is 20 min away from the city center, you can get there by bus, taxi or Uber.',
                )}
              </Typography.Text>
              <Typography.Link href='https://maps.app.goo.gl/fXrKYSqaCmdBozVVA' target='_blank'>
                <PushpinOutlined /> {t('Aeropuerto de Sevilla (SVQ)')}
              </Typography.Link>
            </Row>
            <Row justify='space-around'>
              <Typography.Title level={3}>{t('Where to stay')}</Typography.Title>
            </Row>
            <Row justify='space-around'>
              <Typography.Text>
                {t(
                  'The city center and surroundings are always full of offers, check bookin.com or AirBnb.',
                )}
              </Typography.Text>
              <Image src='/assets/barrios.webp' height={200} />
            </Row>
            <Row justify='space-around'>
              <Typography.Title level={3}>{t('How to move arround')}</Typography.Title>
            </Row>
            <Row justify='space-around'>
              <Typography.Text>
                {t(
                  'If you are not planning to go out the city, just go everywhere by having a walk. You can also explore around the river line by bike, Sevilla is all plane and have lots of kilometers of bike lane to enjoy.',
                )}
              </Typography.Text>
            </Row>
          </Col>
        </Row>
      ),
    },
    {
      key: '2',
      label: t('What to do'),
      children: (
        <Row justify='space-around'>
          <Col span='18'>
            <Row justify='space-around'>
              <Typography.Title level={3}>
                {t('The most popular tourist attractions')}
              </Typography.Title>
              <Image src='/assets/plaza.png' preview={false} />
            </Row>
            <List
              itemLayout='horizontal'
              dataSource={popular}
              renderItem={(item, index) => (
                <List.Item
                  actions={[
                    <Typography.Text key={index + '-visit'}>{item.visit}</Typography.Text>,
                    <Typography.Link key={index + '-link'} href={item.link} target='_blank'>
                      <PushpinOutlined />
                    </Typography.Link>,
                  ]}
                >
                  <List.Item.Meta
                    title={
                      <a href={item.link} target='_blank' rel='noreferrer'>
                        {item.title}
                      </a>
                    }
                  />
                </List.Item>
              )}
            />
            <Row justify='space-around'>
              <Typography.Title level={3}>{t('Museums and Art Galleries')}</Typography.Title>
              <Image src='/assets/museum.png' preview={false} />
            </Row>
            <List
              itemLayout='horizontal'
              dataSource={museums}
              renderItem={(item, index) => (
                <List.Item
                  actions={[
                    <Typography.Link key={index + '-link'} href={item.link} target='_blank'>
                      <PushpinOutlined />
                    </Typography.Link>,
                  ]}
                >
                  <List.Item.Meta
                    title={
                      <a href={item.link} target='_blank' rel='noreferrer'>
                        {item.title}
                      </a>
                    }
                  />
                </List.Item>
              )}
            />
            <Row justify='space-around'>
              <Typography.Title level={3}>{t('Hidden Spots')}</Typography.Title>
              <Image src='/assets/hidden.png' preview={false} />
            </Row>
            <List
              itemLayout='horizontal'
              dataSource={hidden}
              renderItem={(item, index) => (
                <List.Item
                  actions={[
                    <Typography.Link key={index + '-link'} href={item.link} target='_blank'>
                      <PushpinOutlined />
                    </Typography.Link>,
                  ]}
                >
                  <List.Item.Meta
                    title={
                      <a href={item.link} target='_blank' rel='noreferrer'>
                        {item.title}
                      </a>
                    }
                  />
                </List.Item>
              )}
            />
            <Row justify='space-around'>
              <Typography.Title level={3}>{t('Movies and TV')}</Typography.Title>
              <Image src='/assets/movies.png' preview={false} />
            </Row>
            <List
              itemLayout='horizontal'
              dataSource={movies}
              renderItem={(item, index) => (
                <List.Item
                  actions={[
                    <Typography.Link key={index + '-visit'} href={item.visit} target='_blank'>
                      {t('More info')}
                    </Typography.Link>,
                    <Typography.Link key={index + '-link'} href={item.link} target='_blank'>
                      <PushpinOutlined />
                    </Typography.Link>,
                  ]}
                >
                  <List.Item.Meta
                    title={
                      <a href={item.link} target='_blank' rel='noreferrer'>
                        {`${item.title} (${item.movie})`}
                      </a>
                    }
                  />
                </List.Item>
              )}
            />
          </Col>
        </Row>
      ),
    },
    {
      key: '3',
      label: t('Local Food'),
      children: (
        <Row justify='space-around'>
          <Col span='18'>
            <Row justify='space-around'>
              <Typography.Title level={3}>
                {t('Local food')}
              </Typography.Title>
            </Row>
            <Row justify='space-around'>
              <Image src='/assets/food.jpg' preview={false} />
            </Row>
            <Row justify='space-around'>
              <Typography.Title level={3}>
                {t('Give it a try')}
              </Typography.Title>
            </Row>
            <Row justify='space-around'>
              <Col>
                <Tag color="warning">Vegetarian</Tag>
                <Tag color="success">Vegan</Tag>
              </Col>
            </Row>
            <Divider />
            <Row justify='space-around'>
              <Col>
                <Tag color="default">Rabo de Toro</Tag>
                <Tag color="default">Pavía de bacalao</Tag>
                <Tag color="success">Pisto</Tag>
                <Tag color="warning">Tortilla al whisky</Tag>
                <Tag color="default">Cazón en adobo</Tag>
                <Tag color="default">Solomillo al whisky</Tag>
                <Tag color="success">Berenjenas con miel</Tag>
                <Tag color="default">Piripi</Tag>
                <Tag color="success">Salmorejo</Tag>
                <Tag color="warning">Papas alioli</Tag>
                <Tag color="default">Manjar blanco</Tag>
                <Tag color="warning">Campiñones con alioli</Tag>
                <Tag color="default">Puntillitas</Tag>
              </Col>
            </Row>
            <Row justify='space-around'>
              <Typography.Title level={3}>{t('Bars & Restaurants')}</Typography.Title>
            </Row>
            <List
              itemLayout='horizontal'
              dataSource={bars}
              renderItem={(item, index) => (
                <List.Item
                  actions={[
                    <Typography.Link key={index + '-link'} href={item.link} target='_blank'>
                      <PushpinOutlined />
                    </Typography.Link>,
                  ]}
                >
                  <List.Item.Meta
                    title={
                      <a href={item.link} target='_blank' rel='noreferrer'>
                        {item.title}
                      </a>
                    }
                  />
                </List.Item>
              )}
            />
          </Col>
        </Row>
      )
    }
  ]

  return (
    <Content
      style={{
        backgroundColor: 'rgba(255, 255, 255, 0.85)',
        borderRadius: 25,
        paddingBottom: 25,
        minHeight: 450,
      }}
    >
      <Row justify='space-around'>
        <Col span='22'>
          <Row justify='space-around' gutter={[0, 16]}>
            <Col span='24'>
              <Row justify='space-around'>
                <Typography.Title style={{ fontFamily: 'PlayfairDisplay' }}>{t('City')}</Typography.Title>
              </Row>
              <Row justify='space-around'>
                <Alert message={t('Under Construction')} style={{ backgroundColor: '#faad14' }}/>
              </Row>
            </Col>
            <Col span='24'>
              <Image src='/assets/sevilla.png' preview={false} />
            </Col>
          </Row>
          <Tabs defaultActiveKey='1' items={items} />
        </Col>
      </Row>
    </Content>
  )
}
